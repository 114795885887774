import React from 'react'
import PropTypes from 'prop-types'

import AnchorLink from '@components/AnchorLink'

const TwitchUserCard = ({
  displayName,
  profileImageUrl,
  compact = false
}) => {
  return (
    <a className={`twitch-user-card ${compact ? '-compact' : ''}`} href={displayName === 'Unannounced Player' ? undefined : `/players/${displayName}`} target="_blank">
      <div className="avatar">
        {profileImageUrl ? (
          <img className='image' src={profileImageUrl} />
        ) : (
          <div className='unannounced-image'>
            ?
          </div>
        )}
      </div>
      <div className='details'>
        <p className='name'>
          {displayName}
        </p>
        <p className='url'>
          {displayName === 'Unannounced Player' ? (
            <AnchorLink className='-purple chl-text -wbreak'>
              twitch.tv/
            </AnchorLink>
          ) : (
            <AnchorLink href={`https://twitch.tv/${displayName}`} className='-purple chl-text -wbreak'>
              twitch.tv/{displayName}
              <svg className='icon svg-icon -stroke -gray -xs ml-1' viewBox='1 0 14 14'>
                <use xlinkHref='#icon-redirect' />
              </svg>
            </AnchorLink>
          )}
        </p>
      </div>
    </a>
  )
}

TwitchUserCard.propTypes = {
  displayName: PropTypes.string,
  profileImageUrl: PropTypes.string,
  compact: PropTypes.boolean
}

export default TwitchUserCard

